
.main-header {
  overflow: hidden;
  width: 100%;
  .header-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    .header-logo {
      width: 175px;
      height: 100%;
    }
    .menu-button {
      display: none;
      .header-menu {
        width: 50px;
      }
      .header-menu-x{
        font-size: 40px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .main-header {
    overflow: hidden;
    .header-image {
      z-index: 2;
      display: flex;
      align-items: center;
      flex-direction: column;
      .header-logo {
        width: 40%;
        height: 100%;
      }
      .menu-button {
        font-size: '30px';
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        display:flex;
        .header-menu {
          width: 50px;
        }
        .header-menu-x{
          font-size: 40px;
        }
      }
    }
  }
}
