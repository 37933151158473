.services {
  padding: 25px;
  background: white;
  .services-title {
    display: flex;
    font-weight: 400;
    font-size: 38px;
    margin-bottom: 20px;
  }
  .services-silent{
    margin-top: 30px;
    .title-silent{
      font-family: "Raleway";
      font-weight: 500;
      font-size: 22px;
    }
    p{
      font-size: 14px;
      color: #707070;
    }
  }
}

@media only screen and (max-width: 425px) {
  .services {
    padding: 25px;

    .services-title {
      display: flex;
      font-weight: 400;
      font-size: 38px;
      margin-bottom: 20px;
    }
    .services-silent{
      margin-top: 30px;
      .title-silent{
        font-family: "Raleway";
        font-weight: 500;
        font-size: 22px;
      }
      p{
        font-size: 14px;
        color: #707070;
      }
    }
  }
}
