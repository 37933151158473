.policies {
  padding: 0 20px;
  h3{
    text-align: center;
  }
  p{
    text-indent: 2.0em
  }
}
@media only screen and (max-width: 425px) {
  .policies {
    padding: 0 20px;
    h3{
      text-align: center;
    }
    p{
      text-indent: 2.0em
    }
  }
}
