@media only screen and (max-width: 425px) {
  @keyframes fadeInImg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .imagebox {
    display: flex;
    animation: 1s ease-out 0s 1 fadeInImg;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.1s;

    .imagebox-image {
      img {
        filter: brightness(50%);
        width: 100%;
        height: 350px;
      }
    }
    .imagebox-container {
      z-index: 2;
      color: white;
      width: 100%;
      .imagebox-title {
        text-align: center;
        padding: 20px 0;
        font-size: 32px;
        width: 100%;
      }
      .imagebox-content {
        width: 100%;
        text-align: center;
        font-size: 24px;
        // margin-bottom: 75px;
      }
      // .imagebox-button {
      //   text-align: center;
        // color: white;
        // letter-spacing: 3px;
        // width: 300px;
        // height: 70px;
        // font-size: 20px;
        // margin-left: auto;
        // margin-right: auto;
        // border-radius: 12px;

        //           background: #006DAF;
        //   color: "#ffffff",
        //   padding: "10px 20px",
        //   border: "1px solid #c8c8c8",
        //   font: "bold 14px Arial",
        //   cursor: "pointer",
        //   height: "75px",
        //   fontSize: "24px",
        //   borderRadius: "10px"


        // a{
        //   text-decoration: none;
        //   width: 200px;
        // }
      // }
    }
  }
}
