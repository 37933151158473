

  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fadeInImg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  .slick-slider{
    margin-left: auto;
    margin-right: auto;
    .slick-prev:before, .slick-next:before{
      font-size: 50px;
    }
  }
    
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  .slides {
    position: relative;
    width: 600px;
  }

  .slides .slick-prev {
    left: 3%;
  }

  .slides .slick-next {
    right: 10%;
  }



@media only screen and (max-width: 425px) {
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }

  .slick-slider{
    .slick-prev:before, .slick-next:before{
      font-size: 50px;
    }
  }
    
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  .slides {
    position: relative;
    width: 100%;
  }
  .slides .slick-prev {
    left: 3%;
  }

  .slides .slick-next {
    right: 10%;
  }
}
