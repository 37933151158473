.footer-main {
  background-color: #222;
  .footer-container {
    width: 80%;
    margin: auto;
    .footer-header {
      display: flex;
      justify-content: center;
      .footer-title {
        color: #2ea3f2;
        font-size: 30px;
        padding: 16px 0;
      }
      .footer-socials {
          align-self: center;
          padding-left: 20px;
          .footer-option{
              width: 75px;
              img{
                width: 100%;
              }
          }
      }
    }
    .footer-contact {
      color: white;
      font-size: 16px;
      padding: 12px 0;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 425px) {
  .footer-main {
    background-color: #222;
    margin-top: 30px;
    .footer-container {
      width: 80%;
      margin: auto;
      .footer-header {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .footer-title {
          color: #2ea3f2;
          font-size: 30px;
          padding: 16px 0;
        }
        .footer-socials {
            align-self: center;
            padding-left: 0;
            .footer-option{
                width: 50px;
                img{
                  width: 100%;
                }
            }
        }
      }
      .footer-contact {
        color: white;
        font-size: 16px;
        padding: 12px 0;
      }
    }
  }
}
