.mobilemenu{
  display: none;
}
.mobilemenu-off {
  display: none;
}
@media only screen and (max-width: 425px) {
  @keyframes slideIn {
    0% {
      max-height: 0vh;
    }
    100% {
      max-height: 100vh;
    }
  }
  @keyframes slideOut {
    0% {
      transform: translate(0px);
    }
    100% {
      transform: translate(0, -250px);
    }
  }
  .mobilemenu-off {
    display: none;
  }
  .mobilemenu {
    display: flex;
    z-index: 2;
    width: 100%;
    animation: slideIn 0.2s ease-in;
    overflow: hidden;
    .container {
      width: 100%;
      margin-left: auto;
      .mobilemenu-button {
        width: 100%;
        display: flex;
        button {
          width: 50px;
          height: 40px;
        }
      }
      .mobilemenu-title {
        font-size: 25px;
        text-align: center;
      }
      .selections {
        margin-bottom: 20px;
        .option-selected {
          justify-content: center;
          display: flex;
          height: 35px;
          margin: 5px 0;
          font-size: 22px;
          width: 100%;
          text-decoration: none;
          background: #55595c;
          color: white;
        }
        .option {
          justify-content: center;
          display: flex;
          height: 35px;
          margin: 5px 0;
          font-size: 22px;
          width: 100%;
          text-decoration: none;
          color: #55595c;
          :hover {
            background: #55595c;
            color: white;
          }
        }
      }
    }
  }
}
