@media only screen and (max-width: 425px) {
  @keyframes fadeInImg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}
