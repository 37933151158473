.App{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.Inner-App{
  padding: 0 20%;
  background: rgb(254, 254, 255);

}


@media only screen and (max-width: 425px) {
  .App{
    width: 100%;
    }
    .Inner-App{
      padding: 0;
      background: rgb(254, 254, 255);
    
    }
}