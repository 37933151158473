@keyframes slideIn {
  0% {
    max-height: 0vh;
  }
  100% {
    max-height: 100vh;
  }
}
@keyframes slideOut {
  0% {
    transform: translate(0px);
  }
  100% {
    transform: translate(0, -250px);
  }
}
.desktopmenu {
  overflow: hidden;

  .selections {
    margin: 55px 0;
    text-align: center;
    border-bottom: solid 1px;
    .option {
      margin: 0 20px;

      font-size: 28px;
      text-decoration: none;
      color: #55595c;
    
    :hover {
      background: black;
      color: white;
    }
  }
  }
}

@media only screen and (max-width: 425px) {
  .desktopmenu {
    display: none;
  }
}
