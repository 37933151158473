.pricebox {
  margin-bottom: 20px;
  .pricebox-header{
    display: flex;
    margin-bottom: 8px;
    .pricebox-title{
      font-family: "Raleway";
    font-weight: 500;
    font-size: 22px;
    }
    .pricebox-price{
      margin-left: auto;
      margin-right: 20px;
      font-size: 18px;
      color: #f9a392;
    }
  }
  .pricebox-details{
    font-size: 16px;
    color: #707070;
  }
}

@media only screen and (max-width: 425px) {
  .pricebox {
    margin-bottom: 20px;
    .pricebox-header{
      display: flex;
      margin-bottom: 8px;
      .pricebox-title{
        font-family: "Raleway";
      font-weight: 500;
      font-size: 22px;
      }
      .pricebox-price{
        margin-left: auto;
        margin-right: 20px;
        font-size: 18px;
        color: #f9a392;
      }
    }
    .pricebox-details{
      font-size: 16px;
      color: #707070;
    }
  }
}
